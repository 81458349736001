<template>
  <div class="index mwframe">
    <!-- 走马灯背景图 -->
    <div
      class="banner"
      v-if="banner_imgs.length"
      :style="{ height: bannerHeight + 'px' }"
    >
      <ul class="box">
        <li
          class="item"
          :class="banner_index == i ? 'on' : 'noon'"
          v-for="(v, i) in banner_imgs"
          :key="i"
        >
          <a href="javascript:void(0)" @click="handleTo(v.link)">
            <img :src="v.image" alt="banner" width="100%" height="inherit" />
          </a>
        </li>
      </ul>
      <div>
        <i
          v-show="banner_left"
          @click="bannerpre()"
          class="banner_change_icon icon tardiness icon-arrow icon-arrow-left"
          >&#xe62e;</i
        >
        <i
          v-show="banner_right"
          @click="bannernext()"
          class="banner_change_icon icon tardiness icon-arrow icon-arrow-right"
          >&#xe62c;</i
        >
      </div>
     <ul class="el-carousel__indicators el-carousel__indicators--horizontal" style="display:flex;justify-content: space-around">
        <li
          v-for="(v, i) in banner_imgs"
          :key="i"
          class="el-carousel__indicator el-carousel__indicator--horizontal"
          :class="banner_index == i ? 'is-active' : ''"
          style="display:block"
        >
          <button class="el-carousel__button" @click="bannerchange(i)"></button>
        </li>
      </ul>
    </div>
    <message  :message="message"   v-if="message.length>0" />
    <div  style="margin-top:20px" v-if="message.length==0" ></div> 
    <!-- 视频走马灯 -->
    <div class="goods-show mw" v-show="video_list.length > 0"  >
      <div class="swiper-container swiper-container1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(v, i) in video_list"
            :key="i"
            style="display: flex"
          >
            <div class="videos" oncontextmenu="return false">
              <video
                :src="v.video"
                style="width: 100%; height: auto"
                controls
                controlsList="nodownload"
              ></video>
            </div>
            <div class="video-imgs">
              <div class="img">
                <img src="../assets/img/index/splogo.png" alt="" width="100%" />
                <div class="s-transition"></div>
              </div>
              <div
                class="img-card"
                v-for="(item, index) in v.goods_list"
                :key="index"
                @click="todetail(item.goods_id)"
              >
                <img :src="item.image" :alt="item.title" width="100%" />
                <p>{{ item.subtitle }}</p>
                <div class="s-transition"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next" @click="next1()">
        <i class="icon tardiness icon-arrow">&#xe62c;</i>
      </div>
      <div class="swiper-button-prev" @click="prev1()">
        <i class="icon tardiness icon-arrow">&#xe62e;</i>
      </div>
    </div>

    <div class="acitvefirst">
      <div v-if="acitvelist.length == 1" class="activea">
        <img :src="acitvelist[0].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="widthFix" @click="toactive(acitvelist[0].active_type)" />
      </div>
      <div v-if="acitvelist.length == 2" class="activeb">
        <img :src="acitvelist[0].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="aspectFill" @click="toactive(acitvelist[0].active_type)" />
        <img :src="acitvelist[1].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="aspectFill" @click="toactive(acitvelist[1].active_type)" />
      </div>
      <div v-if="acitvelist.length == 3" class="activec">
        <div>
          <img :src="acitvelist[0].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="aspectFill" @click="toactive(acitvelist[0].active_type)" />
          <img :src="acitvelist[1].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="aspectFill" @click="toactive(acitvelist[1].active_type)" />
        </div>
        <div>
          <img :src="acitvelist[2].image||'https://jinwang-keji.oss-cn-beijing.aliyuncs.com/uploads/20221215/089cfda115b53886d2ebdc407919e24f.jpg'" mode="aspectFill" @click="toactive(acitvelist[2].active_type)" />
        </div>
      </div>
    </div>

    <!-- 新品推荐 -->
    <div
      class="new-recommend mw"
      :class="is_show_new_recommend ? 'box_show' : 'box_hide'"
      v-show="recd_imgs.length"
      ref="new_recommend"
    >
      <p class="h1">NEW ARRIVALS</p>
      <div class="h3">新品推荐</div>
      <div class="replace">
        <div
          class="replace_checks"
          :class="is_show_new_recommend ? 'left_enter' : 'left_hide'"
        >
          <div
            class="replace_checks_check"
            v-for="(item, index) in recd_imgs"
            :key="index"
            :style="index == 2 ? 'margin-bottom:0' : ''"
            @mouseenter="rectChange(index)"
            @click="toshopList(item.id)"
          >
            <img :src="item.watermark" alt="" />
          </div>
        </div>
        <div
          class="replace_img"
          :class="is_show_new_recommend ? 'right_enter' : 'right_hide'"
        >
          <div v-for="(item, index) in recd_imgs" :key="index">
            <img
              :class="recd_img_index == index ? 'fade_in' : 'fade_out'"
              :src="item.image"
              alt
              @click="imgtolist(item.image)"
            />
          </div>
        </div>
      </div>
      <div class="rightNow">
        <i>{{year}}</i> {{ welcome_message  }} 
        <div class="btn" @click="recdtolist">即刻享购</div>
      </div>
    </div>
    <!-- VIP专区 -->
    <div
      class="vip mw"
      ref="vip"
      :class="is_show_vip ? 'box_show' : 'box_hide'"
    >
      <a
        class="W"
        :style="'background-image:url(' + member_banner.image + ')'"
        :href="member_banner.link"
      >
        <div class="content content_img">
          <div class="animals_one">
            <img
              src="../assets/img/index/tutuearleft.png"
              alt="兔子"
              class="leftear"
            />
            <img
              src="../assets/img/index/tutuearright.png"
              alt="兔子"
              class="rightear"
            />
            <img src="../assets/img/index/tutu.png" alt="兔子" class="head" />
          </div>
        </div>
        <div class="content content_p">
          <!-- <p class="p1">VIP</p>
          <p class="p2">ENTRANCE</p>
          <p class="p3">会员专区</p> -->
        </div>
        <div class="content content_img">
          <div class="animals_two">
            <img
              src="../assets/img/index/bearearleft.png"
              class="leftbearear"
              alt="熊"
            />
            <img
              src="../assets/img/index/bearearright.png"
              class="rightbearear"
              alt="熊"
            />
            <img src="../assets/img/index/bear.png" class="head" alt="熊" />
          </div>
        </div>
      </a>
    </div>
    <!-- 热门商品 -->
    <div
      class="hot-product mw"
      v-if="hotclassify.length"
      :class="is_show_hot_product ? 'box_show' : 'box_hide'"
      ref="hot_product"
    >
      <div class="hot-product-title">
        <div class="hot-product-title-EN">
          <p class="hot-product-title-text">PRODUCTS</p>
          <p class="hot-product-line"></p>
        </div>
        <p class="hot-product-title-CN">热门品类</p>
      </div>
      <div class="hot-prouct-images">
        <div
          class="img"
          v-for="(item, index) in hotclassify"
          :key="index"
          @click="toshopclassify(item.classify_ids)"
        >
          <img :src="item.image" :alt="item.title" />
          <!-- <div class="textsone">
            <p class="title">GIRLS</p>
            <p class="chinese">女童</p>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 主题编辑 -->
    <div
      class="pickup mw"
      v-if="themeData.length"
      :class="is_show_pickup ? 'box_show' : 'box_hide'"
      ref="pickup"
    >
      <div class="pickup-title">
        <p class="title_EN">PICK UP</p>
        <p class="title_CN">主题特辑</p>
      </div>
      <div class="pickup-images">
        <a
          class="img"
          v-for="(item, index) in themeData"
          :key="index"
          :href="item.link"
        >
          <img :src="item.image" :alt="item.title" />
        </a>
      </div>
    </div>
    <!-- 限时特优 -->
    <div
      class="timesale"
      ref="timesale"
      v-show="is_show_timesale && is_show_timesale2"
    >
      <div class="timesale_left">
        <p class="timesale_left-title">TIME SALE</p>
        <div class="timesale_left-content">
          <div>
            <div class="swiper-container swiper-container2">
              <div class="swiper-wrapper">
                <div
                  class="small-card swiper-slide"
                  v-for="(item, key) in seckill_goods"
                  :key="key"
                >
                  <img
                    :src="item.image"
                    alt="1"
                    @click="todetail(item.goods_id)"
                  />
                </div>
              </div>
              <div class="swiper-button-prev swiper_prev2">
                <i class="icon tardiness icon-arrow">&#xe62e;</i>
              </div>
              <div class="swiper-button-next swiper_next2">
                <i class="icon tardiness icon-arrow">&#xe62c;</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timesale_right">
        <div class="timesale_right-title">限时特优</div>
        <p class="text">{{ active_tip }}</p>
        <p class="time" v-html="time(pre_at)"></p>
        <div class="btn" @click="toseckill">立即抢购</div>
      </div>
    </div>
    <!-- 超值精选 -->
    <div
      class="outlets mw"
      ref="outlets"
      :class="is_show_outlets ? 'box_show' : 'box_hide'"
      v-show="selected.length > 0"
    >
      <div class="outlets-title">
        <p class="title_EN">OUTLETS</p>
        <p class="title_CN">超值精选</p>
        <div
          class="logoImg"
          @click="tolist"
          :class="is_show_outlets ? 'left_enter' : 'left_hide'"
        >
          <img src="@/assets/img/index/wholebear.png" alt="" />
          <div class="btn">MORE</div>
        </div>
      </div>
      <div class="outlets-content">
        <div
          class="outlets-content-card"
          v-for="(item, index) in selected"
          :key="index"
          :style="index >= 5 ? 'border-top: none' : ''"
          @click="todetail(item.goods_id,2)"
        >
          <img :src="item.image" :alt="item.subtitle" />
          <p><i class="icon">&#xe76e;</i> {{ item.subtitle }}</p>
        </div>
      </div>
    </div>
    <div class="mask" v-if="show_box">
      <div class="windowspassword" v-if="show_box">
        <div class="widowtitle">
          请绑定手机号
          <div class="icon tardiness" @click="show_box = false">&#xe61c;</div>
        </div>
        <el-form class="contents">
          <el-form-item label="手机号码" prop="user">
            <el-input v-model="mobile" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input v-model="captcha" placeholder="请输入短信验证码"
              ><el-button
                slot="append"
                @click="sendemail2"
                :loading="loading2"
                :disabled="cannot2"
                style="width: 140px"
                >{{ countdetail2 }}</el-button
              ></el-input
            >
          </el-form-item>
          <div class="savebtn" @click="bindmobile()">立即保存</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "../../node_modules/swiper/dist/css/swiper.min.css";
import "../../node_modules/swiper/dist/js/swiper.min";
export default {
  props: [],
  data() {
    return {
      acitvelist: [
      ],
      banner_imgs: [], //banner
      banner_index: 0,
      banner_left: false,
      banner_right: false,
      is_show_new_recommend: false,
      is_show_vip: false,
      is_show_hot_product: false,
      is_show_pickup: false,
      is_show_outlets: false,
      is_show_timesale: false,
      is_show_timesale2: true,
      intel: "",
      video_list: [], //videos
      videoShops: [], //video关联商品
      member_banner: {}, //会员专区背景图
      recd_imgs: {}, //推荐
      recd_img_index: 0,
      recdImg: 0, //推荐商品大图
      themeData: [], //主题
      hotclassify: [], //热门分类
      selected: [], //精选
      seckill_goods: [],
      seckill: {},
      goodsShow_list: [
        {
          video: "",
          imgs: [
            { desc: "", img: "" },
            { desc: "", img: "" },
            { desc: "", img: "" },
          ],
          logs: "",
        },
      ],
      navList: [],

      // 图片父容器高度
      bannerHeight: 842,
      // 浏览器宽度
      screenWidth: 0,
      videoHeight: 842,
      swiper1: null,
      swiper2: null,
      active_tip: "",
      pre_at: 0,
      userid: "",
      mobile: "",
      captcha: "",
      countdetail2: "获取手机验证码",
      cannot2: false,
      loading2: false,
      show_box: false,
      welcome_message:"",
      message:[],
      year:""
    };
  },
  created() {
    let code = this.$route.query.code;
    let state = this.$route.query.state;
    if (code && state) {
      this.login(code, state);
    }
    this.getmes()
  },


  mounted() {
    this.getPageIndex();
    // 首次加载时,需要调用一次
    this.screenWidth = window.innerWidth;
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize();
    };
    window.addEventListener("scroll", this.handleScroll);
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
  },
  watch: {
    video_list() {
      this.$nextTick(() => {
        this.swiper1 = new Swiper(".swiper-container1", {
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    },
    banner_imgs() {
      console.log(111);
      this.$nextTick(() => {
        clearInterval(this.intel);
        this.intel = setInterval(() => {
          this.bannerchange();
        }, 5000);
      });
    },
    seckill_goods() {
      this.$nextTick(() => {
        this.swiper2 = new Swiper(".swiper-container2", {
          watchSlidesProgress: true,
          slidesPerdiv: 4,
          navigation: {
            nextEl: ".swiper_next2",
            prevEl: ".swiper_prev2",
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            progress: function (progress) {
              if (!isNaN(progress)) {
                for (let i = 0; i < this.slides.length; i++) {
                  var slide = this.slides.eq(i);
                  var slideProgress = parseFloat(
                    this.slides[i].progress.toFixed(2)
                  );
                  let scale = 1;
                  let x = 0;
                  if (slideProgress >= -3 && slideProgress < -2) {
                    scale = 1 + Math.abs(slideProgress) * 0.1;
                  }
                  if (slideProgress == -3) {
                    x = 20;
                  }
                  slide.transform(
                    "scale(" + scale + ") translateX(" + x + "px)"
                  );
                }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                slide.transition(transition);
              }
            },
          },
        });
      });
    },
  },
  methods: {
getmes(){
this.$myAjax(
      "zkp/roll_notice_list",
      "get",
      { identity_id: this.userid },
      (res) => {
        this.message = res;
      }
    );
},
    toactive(active_type) {
       localStorage.removeItem("page")
     this.$router.push({
        name: "seriesDetailsactive",
        query: {
          active_type:active_type,
        },
      });
    },
    sendemail2() {
      if (this.mobile) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.mobile,
            event: "bindmobile",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset: 170,
            });
            let count2 = 60;
            this.loading2 = true;
            this.cannot2 = true;
            var interval = setInterval(() => {
              this.loading2 = false;
              count2--;
              if (count2 == 0) {
                clearInterval(interval);
                this.countdetail2 = "获取验证码";
                this.cannot2 = false;
              } else {
                this.countdetail2 = count2 + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写手机号码",
          offset: 170,
        });
      }
    },
    bindmobile() {
      if (!this.mobile) {
        this.$message.error("请填写手机号");
        return;
      }
      if (!this.captcha) {
        this.$message.error("请填写验证码");
        return;
      }
      this.$myAjax(
        "wechat/binding_mobile",
        "post",
        {
          mobile: this.mobile,
          captcha: this.captcha,
        },
        (res) => {
          localStorage.removeItem("token");
          localStorage.setItem("token", res.user_info.token);
          localStorage.setItem("userId", res.user_info.id);
          this.$message({
            showClose: true,
            message: "绑定成功",
            type: "success",
            offset: 170,
            onClose: () => {
              this.$router.replace({
                name: "HomeCenter",
              });
            },
          });
        }
      );
    },
    login(code, state) {
      this.$myAjax(
        "wechat/call_back_scan",
        "post",
        { code, state },
        (res) => {
          if (res.code == -3) {
            this.show_box = true;
          } else if (res.code == 1) {
            this.$message({
              showClose: true,
              message: "登录成功",
              type: "success",
              offset: 170,
            });
            localStorage.removeItem("token");
            localStorage.setItem("token", res.data.user_info.token);
            localStorage.setItem("userId", res.data.user_info.id);
            this.$router.replace({
              name: "HomeCenter",
            });
          } else {
            this.$alert("请重新授权", "MIKI HOUSE提示你", {
              confirmButtonText: "确定",
              callback: () => {
                this.$router.push({
                  name: "login",
                });
              },
            });
          }
        },
        true
      );
    },
    //初始化获取首页数据
    getPageIndex: function () {
      this.$myAjax(
        "pc/home_page",
        "get",
        { identity_id: this.userid },
        (res) => {
          this.banner_imgs = res.banner;
          localStorage.setItem("contact",res.contact)
          if (this.banner_imgs.length > 1) {
            this.banner_right = true;
          }
          // res.banner.map((item) => {
          //   this.banner_imgs.push(item.image);
          //   if (this.banner_imgs.length > 1) {
          //     this.banner_right = true;
          //   }
          // });
          this.video_list = res.video;
          // this.videoShops = this.video_list[0].goods_list;
          this.member_banner = res.member_banner[0];
          this.recd_imgs = res.recd;
          this.recdImg = this.recd_imgs[0].image;
          this.themeData = res.theme;
          this.hotclassify = res.hot_classify;
          this.selected = res.selected;
          this.seckill = res.seckill;
          this.seckill_goods = res.seckill.goods;
          this.acitvelist=res.active
          this.welcome_message=res.welcome_message
          this.year=(new Date()).getFullYear()
          if (typeof res.seckill == "object") {
            this.is_show_timesale2 = true;
            let nowtime = new Date().getTime();
            if (nowtime < res.seckill.time.start_time_int * 1000) {
              this.active_tip = "距离活动开始还剩";
              this.pre_at =
               ( res.seckill.time.start_time_int * 1000) - new Date().getTime();
                console.log(" this.pre_at", this.pre_at/1000)
              this.ticker = setInterval(() => {
                this.pre_at = this.pre_at - 1000;
              }, 1000);
            } else if (nowtime < res.seckill.time.end_time_int * 1000) {
              this.active_tip = "距离活动结束还剩";
              // this.is_show_timesale2 = true;
              this.pre_at =
                res.seckill.time.end_time_int * 1000 - new Date().getTime();
              this.ticker = setInterval(() => {
                this.pre_at = this.pre_at - 1000;
              }, 1000);
            } else {
              this.active_tip = "活动已结束";
              // this.is_show_timesale2 = false;
            }
          } else {
            this.is_show_timesale2 = false;
          }
        }
      );
    },
    //视频切换
    videochange(e) {
      this.videoShops = this.video_list[e].goods_list;
      let video_ = document.getElementById("video");
      video_.pause();
    },
    //推荐商品切换
    rectChange(e) {
      this.recdImg = this.recd_imgs[e].image;
      this.recd_img_index = e;
    },
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight =
        (842 / 1920) * this.screenWidth >= 596.14
          ? (842 / 1920) * this.screenWidth
          : 596.14;
      this.videoHeight =
        (646 / 1420) * this.screenWidth >= 680
          ? (646 / 1420) * this.screenWidth
          : 680;
    },
    toshopList(recid) {
      localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          recid: recid,
        },
      });
    },
    imgtolist(_img) {
      this.recd_imgs.map((item) => {
        if (item.image == _img) {
          this.toshopList(item.id);
        }
      });
    },
    toshopclassify(classify_ids) {
          localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          classify_ids: classify_ids,
          issend: true,
        },
      });
    },
    todetail(_id,type) {
      if(type){
        this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}&active_type=${type}`),
        },
      });
      }else{
        this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
      });
      }
     
    },
    tolist() {
      this.$router.push({
        name: "SuperChoose",
        query: {
          title: "超值精选",
        },
      });
    },
    recdtolist() {
      let recid = [];
      this.recd_imgs.map((item) => {
        recid.push(item.id);
      });
          localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          recid: recid.toString(),
          title: "新品推荐",
        },
      });
    },

    bannerchange: function (index) {
      clearInterval(this.intel);
      this.intel = null;
      if (typeof index === "undefined") {
        if (this.banner_imgs.length <= this.banner_index + 1) {
          index = 0;
        } else {
          index = this.banner_index + 1;
        }
      }
      if (this.banner_imgs.length - 1 > index) {
        this.banner_right = true;
      } else {
        this.banner_right = false;
      }
      if (index === 0) {
        this.banner_left = false;
      } else {
        this.banner_left = true;
      }
      this.banner_index = index;
      this.intel = setInterval(() => {
        this.bannerchange();
      }, 5000);
    },
    bannerpre: function () {
      this.bannerchange(this.banner_index - 1);
    },
    bannernext: function () {
      this.bannerchange(this.banner_index + 1);
    },
    handleScroll: function () {
      this.currentScroll = window.pageYOffset;
      this.innerHeight = window.innerHeight;
      if (
        this.$refs.new_recommend &&
        this.currentScroll + this.innerHeight >=
          this.$refs.new_recommend.offsetTop + 150
      ) {
        this.is_show_new_recommend = true;
      }
      if (
        this.$refs.vip &&
        this.currentScroll + this.innerHeight >= this.$refs.vip.offsetTop + 150
      ) {
        this.is_show_vip = true;
      }
      if (
        this.$refs.hot_product &&
        this.currentScroll + this.innerHeight >=
          this.$refs.hot_product.offsetTop + 150
      ) {
        this.is_show_hot_product = true;
      }
      if (
        this.$refs.pickup &&
        this.currentScroll + this.innerHeight >=
          this.$refs.pickup.offsetTop + 150
      ) {
        this.is_show_pickup = true;
      }
      if (
        this.$refs.timesale &&
        this.currentScroll + this.innerHeight >=
          this.$refs.timesale.offsetTop + 150
      ) {
        this.is_show_timesale = true;
      }
      if (
        this.$refs.outlets &&
        this.currentScroll + this.innerHeight >=
          this.$refs.outlets.offsetTop + 150
      ) {
        this.is_show_outlets = true;
      }
    },
    prev1() {
      this.swiper1.slidePrev();
    },
    next1() {
      this.swiper1.slideNext();
    },
    time(time) {
      console.log("time",this.pre_at,time)
      if (time >= 0) {
        let h =
          Math.floor((time / 1000 / 60 / 60) ) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) )
            : Math.floor((time / 1000 / 60 / 60) );
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return `<span class="time_box">${h}</span>：<span class="time_box">${m}</span
            >：<span class="time_box">${s}</span>`;
      } else {
        let nowtime = new Date().getTime();
        if (nowtime < this.seckill.time.start_time_int * 1000) {
          this.active_tip = "距离活动开始还剩";
          this.pre_at =
            this.seckill.time.start_time_int * 1000 - new Date().getTime();
           
        } else if (nowtime < this.seckill.time.end_time_int * 1000) {
          this.active_tip = "距离活动结束还剩";
          this.pre_at =
            this.seckill.time.end_time_int * 1000 - new Date().getTime();
        } else {
          clearInterval(this.ticker);
          this.active_tip = "活动已结束";
          this.is_show_timesale2 = false;
          return ``;
        }
      }
    },
    toseckill() {
      this.$router.push({
        name: "flashSale",
      });
    },
    handleTo(link) {
      if (link != "") {
        window.location.href = link;
      }
    },
  },
};
</script>
<style lang="less">
// @media (min-width: 1360px) {
//   .el-carousel__container {
//     height: 842px;
//   }
// }
// @media (max-width: 1360px) {
//   .el-carousel__container {
//     height: 630px;
//   }
// }
.el-carousel__indicators--horizontal {
  bottom: 65px;
  left: unset;
  right: 5%;
}

.el-carousel__indicator--horizontal {
  padding: 12px 10px;
}

.el-carousel__button {
  background-image: url("~@/assets/img/index/bannernormal.png") !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat;
  height: 26.5px;
  width: 33px;
  opacity: 1;
  background: transparent;
}
.el-carousel__indicator.is-active button {
  background-image: url("~@/assets/img/index/banneron.png") !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat;
}

@keyframes _hidden {
  to {
    width: 0;
    // z-index: -1;
  }
}

.acitvefirst {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px  0px;

  .activea {
    max-width: 1640px;
    height: 356px;
    img {
      width: 100%;
      height: 356px;
    }
  }
  .activeb {
    max-width: 1640px;
    height: 356px;
    display: flex;
    justify-content: space-around;
    img {
      width: 45%;
      height: 100%;
    }
  }
  .activec {
    max-width: 1640px;
    height: 728px;
    div:nth-child(1) {
      width: 100%;
      height:356px;
      display: flex;
      justify-content: space-between;
      img {
        width: 49%;
        height: 356px;
      }
    }
    div:nth-child(2) {
      margin-top: 28px;
      width: 100%;
      height:356px;
      img {
        width: 100%;
        height:356px;
      }
    }
  }
}
</style>



<style lang="less">
.goods-show {
  .swiper-container1 {
    .swiper-slide {
      padding: 0 10px;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-image: unset;
    outline: none;
    width: auto;
    height: auto;
    transform: translateY(-50%);
  }
}
.timesale .timesale_left {
  .timesale_left-content {
    .swiper-button-next,
    .swiper-button-prev {
      background-image: unset;
      line-height: 44px;
      &.swiper-button-disabled {
        pointer-events: unset;
      }
    }
    .swiper-button-next {
      right: 50px;
    }
  }
}

.goods-show {
  .el-icon-arrow-right,
  .el-icon-arrow-left {
    font-size: 30px;
    color: #999;
    box-shadow: 0 0 5px 5px #f5f7fa;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    &:hover {
      color: #df1329;
    }
  }
}
</style>
<style lang="less">
.goods-show {
  padding: 0 100px;
  .el-carousel__arrow {
    background-color: rgba(255, 255, 255, 0);
    width: auto;
    height: auto;
  }
  .el-carousel__arrow--left {
    left: -100px;
  }
  .el-carousel__arrow--right {
    right: -100px;
  }
}
</style>
<style lang="less" scoped>
.icon-arrow {
  color: #999999;
  font-size: 100px;
  &:hover {
    color: #fa4750;
  }
}
.title_EN {
  font-size: 60px;
  color: #fa4750;
  font-style: italic;
  font-weight: 200;
}
.title_CN {
  font-size: 40px;
  color: #fa4750;
}
.index {
  background-color: #fff;
  .banner {
    position: relative;
    overflow: hidden;
    ul.box {
      li {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: all 1s linear;
        opacity: 0;
        &.noon {
          animation: _hidden 0s linear 1s forwards;
        }
        &.on {
          opacity: 1;
          animation: banner_scale 2s ease-in-out;
          @keyframes banner_scale {
            50% {
              transform: scale3d(1.01, 1.01, 1);
            }
            100% {
              transform: scale3d(1, 1, 1);
            }
          }
        }
        &.live {
          opacity: 0;
        }
      }
    }
    .banner_change_icon {
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50px);
      transition: all 0.5 linear;
      cursor: pointer;
      &:hover {
        color: #df1329;
      }
    }
    .icon-arrow-right {
      right: 40px;
    }
    .icon-arrow-left {
      left: 40px;
    }
    &:hover {
      .banner_change_icon {
        opacity: 1;
      }
    }
  }
}
.goods-show {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  .el-carousel__item {
    // padding: 0 100px;
  }
  .el-carousel__arrow {
    background-color: rgba(255, 255, 255, 0);
  }
  .el-carousel__arrow--left {
    left: -50px;
  }
  .el-carousel__arrow--right {
    right: -50px;
  }
  .videos {
    width: 86.5%;
    margin-right: 30px;
    float: left;
    cursor: pointer;
  }
  .video-imgs {
    // width: 13.5%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    // justify-content: space-between;
    .img {
      img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }
    .img-card {
      // width: 190px;
      img {
        width: 100%;
        cursor: pointer;
        height: auto;
      }
    }
  }
  .img:hover {
    .s-transition {
      width: 100%;
    }
  }
  .img-card:hover {
    .s-transition {
      width: 100%;
    }
  }
  .s-transition {
    width: 0px;
    border-bottom: 1px solid #f22;
    color: red;
    margin: 0 auto;
    transition: all 0.5s;
  }
  .img-card {
    font-size: 12px;
    color: #f66769;
    width: 100%;
    p {
      text-align: center;
      margin: 0 0 9px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}
.box_show {
  transform: translateY(0);
  opacity: 1;
}
.box_hide {
  transform: translateY(150px);
  opacity: 0;
}
.left_enter {
  transform: translateX(0);
  opacity: 1;
}
.left_hide {
  transform: translateX(-100%);
  opacity: 0;
}
.right_enter {
  transform: translateX(0);
  opacity: 1;
}
.right_hide {
  transform: translateX(100%);
  opacity: 0;
}
// img.fade_in {
//   opacity: 1;
//   display: inherit;
// }
// img.fade_out {
//   opacity: 0;
//   animation: _hidden 0.1s linear 1s;
// }
// @keyframes _hidden {
//   to {
//     width: 0 !important;
//   }
// }
.new-recommend {
  margin-top: 100px;
  transition: all 0.5s ease-in-out;
  .h1,
  .h3 {
    text-align: center;
  }
  .h1 {
    font-size: 60px;
    color: #fa4750;

    font-style: italic;
    font-weight: 200;
  }
  .h3 {
    font-size: 40px;
    color: #fa4750;
    margin-bottom: 50px;
  }
  .replace {
    display: flex;
    justify-content: space-around;
    .replace_checks {
      width: 31.4%;
      transition: all 1s ease-in-out;

      .replace_checks_check {
        opacity: 0.8;
        height: auto;
        margin-bottom: 5px;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        img {
          width: 100%;
        }
        .desc {
          p {
            font-size: 16px;
            color: #fff;
          }
        }
        .bigText {
          p:nth-of-type(1) {
            font-size: 44px;
            color: #fff;
            span {
              font-size: 24px;
              letter-spacing: 5px;
              margin-left: 5px;
              font-weight: lighter;
              color: rgba(255, 255, 255, 0.8);
            }
          }
          p:nth-of-type(2) {
            font-size: 28px;
            letter-spacing: 5px;
            color: rgba(255, 255, 255, 0.8);
            font-weight: lighter;
            span {
              font-style: italic;
            }
          }
        }
      }
      .replace_checks_check:hover {
        opacity: 1;
        transition: all 0.5s;
      }
    }
    .replace_img {
      flex: 1;
      height: auto;
      transition: all 1s ease-in-out;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        z-index: 1;
        &.fade_in {
          opacity: 1;
        }
        &.fade_out {
          opacity: 0;
          animation: _hidden 0s linear 0.5s forwards;
        }
      }
    }
  }
  .rightNow {
    border: 1px solid #fee9ea;
    border-top: none;
    height: 106px;
    text-align: center;
    font-size: 36px;
    color: #fa4750;
    line-height: 106px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    i {
      font-style: italic;
      color: #feb5b8;
      margin-right: 20px;
    }
    .btn {
      position: relative;
      width: 140px;
      text-align: center;
      border: 1px solid #fa454f;
      cursor: pointer;
      border-radius: 4px;
      color: #fa454f;
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
      font-size: 14px;
      border-radius: 5px;
    }
    .btn:hover {
      background: #fa454f;
      color: #fff;
      transition: all 0.5s;
    }
  }
}
.vip {
  margin-top: 100px;
  margin-bottom: 100px;
  transition: all 0.5s ease-in-out;
  .W {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    height: 356px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .content {
      img {
      }
      > p {
        color: #fff;
        text-align: center;
      }
      .p1 {
        font-size: 100px;
        letter-spacing: 15px;
        font-weight: lighter;
        font-family: monospace;
      }
      .p2 {
        font-size: 16px;
        letter-spacing: 5px;
      }
      .p3 {
        font-size: 38px;
        font-weight: lighter;
        letter-spacing: 15px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .content_img {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      margin-top: 10px;
      .animals_one {
        cursor: pointer;
        position: relative;
        z-index: 9;
        .head {
          width: 173px;
          height: 158px;
        }
        .leftear {
          width: 68.5px;
          position: absolute;
          bottom: 102.5px;
          left: 0;
          z-index: -1;
        }
        .rightear {
          width: 68.5px;
          position: absolute;
          bottom: 102.5px;
          right: -2px;
          z-index: -1;
        }
      }
      .animals_two {
        cursor: pointer;
        position: relative;
        z-index: 9;
        .head {
          width: 173px;
          height: 158px;
        }
        .leftbearear {
          width: 90px;
          position: absolute;
          left: -26px;
          bottom: 95px;
          z-index: -1;
        }
        .rightbearear {
          width: 90px;
          position: absolute;
          right: -26px;
          bottom: 95px;
          z-index: -1;
        }
      }
    }
    .content_p {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover {
      .leftear {
        transform: translate(0%, 0%);
        animation: korokorotL 0.5s ease-in-out 0s;
        @keyframes korokorotL {
          0% {
            transform: translate(0%, 0%);
          }
          25% {
            transform: translate(-2%, 0) rotate(-2deg);
          }
          50% {
            transform: translate(-5%, 0) rotate(-5deg);
          }
          75% {
            transform: translate(-2%, 0) rotate(-2deg);
          }
          100% {
            transform: translate(0%, 0%);
          }
        }
      }
      .rightear {
        transform: translate(0%, 0%);
        animation: korokorotR 0.5s linear 0s;
        @keyframes korokorotR {
          0% {
            transform: translate(0%, 0%);
          }
          25% {
            transform: translate(4%, 0%) rotate(4deg);
          }
          50% {
            transform: translate(8%, 0%) rotate(8deg);
          }
          75% {
            transform: translate(4%, 0%) rotate(4deg);
          }
          100% {
            transform: translate(0%, 0%);
          }
        }
      }

      .leftbearear {
        transform: translate(0%, 0%);
        animation: korokoroL 0.5s ease-in-out 0s;
        @keyframes korokoroL {
          0% {
            transform: translate(0%, 0%);
          }
          25% {
            transform: translate(-2%, 0) rotate(-2deg);
          }
          50% {
            transform: translate(-5%, 0) rotate(-5deg);
          }
          75% {
            transform: translate(-2%, 0) rotate(-2deg);
          }
          100% {
            transform: translate(0%, 0%);
          }
        }
      }
      .rightbearear {
        transform: translate(0%, 0%);
        animation: korokoroR 0.5s linear 0s;
        @keyframes korokoroR {
          0% {
            transform: translate(0%, 0%);
          }
          25% {
            transform: translate(2%, 0%) rotate(2deg);
          }
          50% {
            transform: translate(5%, 0%) rotate(5deg);
          }
          75% {
            transform: translate(2%, 0%) rotate(2deg);
          }
          100% {
            transform: translate(0%, 0%);
          }
        }
      }
    }
  }
}
.hot-product {
  transition: all 0.5s ease-in-out;
  margin-bottom: 100px;
  .hot-product-title {
    margin-bottom: 50px;
    p {
      text-align: center;
    }
    .hot-product-title-EN {
      text-align: center;
      position: relative;
      .hot-product-title-text {
        font-size: 60px;
        font-style: italic;
        font-weight: 200;
        color: #fa4750;
        background: #fff;
        display: inline-block;
        margin: 0 auto;
        position: relative;
        z-index: 99;
      }
      .hot-product-line {
        border: 1px solid rgba(250, 71, 80, 0.67);
        position: absolute;
        width: 100%;
        top: 50%;
        height: 200%;
        border-bottom: transparent;
      }
    }
    .hot-product-title-CN {
      font-size: 40px;
      color: #fa4750;
    }
  }
  .hot-prouct-images {
    display: flex;
    overflow: hidden;
    border: 1px solid rgba(250, 71, 80, 0.67);
    border-top: transparent;
    .img {
      width: 25%;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 0.5s;
        }
      }
      .textsone {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #fff;
        padding: 10px;
        .title {
          font-size: 66px;
          text-align: right;
          font-weight: bold;
          font-family: -webkit-pictograph;
        }
        .chinese {
          font-size: 24px;
          text-align: right;
        }
      }
      .textstwo {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        padding: 10px;
        .title {
          font-size: 66px;
          text-align: left;
          font-weight: bold;
          font-family: -webkit-pictograph;
        }
        .chinese {
          font-size: 24px;
          text-align: left;
        }
      }
      .textsthree {
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
        padding: 10px;
        .title {
          font-size: 66px;
          text-align: left;
          font-weight: bold;
          font-family: -webkit-pictograph;
        }
        .chinese {
          font-size: 24px;
          text-align: left;
        }
      }
    }
  }
}
.pickup {
  transition: all 0.5s;
  .pickup-title {
    margin-top: 100px;
    margin-bottom: 50px;
    p {
      text-align: center;
    }
  }
  .pickup-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .img {
      width: 49.8%;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .img:hover {
      opacity: 0.8;
      transition: all 0.5s;
    }
  }
}
.timesale {
  min-width: 1360px;
  height: 600px;
  border: 1px solid #fa454f;
  display: flex;
  margin-top: 102px;
  margin-bottom: 102px;
  justify-content: space-between;
  overflow: hidden;
  .timesale_left {
    width: 60%;
    // width: 1000px;
    background-color: #fa454f;
    padding: 50px 0 0 100px;
    .timesale_left-title {
      font-size: 54px;
      color: #ffffff;
    }
    .timesale_left-content {
      width: 110%;
      min-height: 400px;
      line-height: 400px;
      z-index: 5;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      i.lf {
        color: #fff;
        cursor: pointer;
      }
      i.rf {
        color: red;
        cursor: pointer;
        z-index: 5;
      }
      .small-card {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          background-color: #fff;
          width: 100%;
          height: 100%;
        }
      }
      .big-card {
        max-width: 36.7%;
        min-height: 309px;
        padding: 55px;
        background-color: #fff;
        border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 5;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .timesale_right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .timesale_right-title {
      font-size: 60px;
      color: #f66769;
    }
    p.text {
      font-size: 26px;
      color: #f66769;
      font-weight: lighter;
      margin: 20px 0;
    }
    p.time {
      font-size: 48px;
      color: #f66769;
      span {
        font-size: 26px;
      }
    }
    div.btn {
      width: 163px;
      height: 38px;
      line-height: 38px;
      margin-top: 37px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      border: 1px solid #f66769;
      color: #f66769;
    }
    div.btn:hover {
      color: #f66769;
      background: #f66769;
      color: #fff;
      transition: all 0.5s;
    }
  }
}
.outlets {
  margin-top: 100px;
  margin-bottom: 100px;
  transition: all 0.5s;
  .outlets-title {
    p {
      text-align: center;
    }
    .logoImg {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: -64px;
      // transition: all 1s linear;
      justify-content: flex-end;
      img {
        width: 73px;
        height: auto;
      }
      .btn {
        width: 96px;
        height: 26px;
        background: #fff;
        border: 1px solid #f66769;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        font-size: 12px;
        color: #f66769;
        cursor: pointer;
        margin-left: 15px;
        &:hover {
          color: #fff;
          background: #f66769;
          transition: all 0.5s;
        }
      }
    }
  }
  .outlets-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 19px;
    // display: grid;
    .outlets-content-card {
      width: 20%;
      // height: 310px;
      min-height: 300px;
      border: 1px solid #e3e1e1;
      border-left: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      &:hover {
        opacity: 0.6;
        transition: all 0.5s;
      }
      img {
        width: 187px;
        height: auto;
      }
      p {
        text-align: start;
        font-size: 14px;
        color: #bdb9b9;
        left: 30px;
        bottom: 10px;
        position: absolute;
        i {
          color: #fa454f;
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .outlets-content-card:nth-of-type(5n + 1) {
      border-left: 1px solid #e3e1e1;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;
  .windowspassword {
    width: 603px;
    height: 501px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -250.5px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 40px;
      .title {
        font-size: 14px;
        color: #000000;
      }
      .eleinput {
        width: 100%;
        margin-top: 16px;
      }
      .savebtn {
        width: 182px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #e60012;
        margin-top: 33px;
        font-size: 16px;
        font-weight: bold;
        color: #e60012;
        text-align: center;
        line-height: 48px;
        position: relative;
        left: 50%;
        margin-left: -91px;
        cursor: pointer;
      }
    }
  }
}
</style>
